
    body::-webkit-scrollbar {
      display: none;
    }

    body {
      /* background-color: rgb(4, 7, 27); */
      background-image: linear-gradient(to right, rgb(4, 7, 27) 50%, rgb(78, 126, 151));

      /* overflow-y: hidden; Hide vertical scrollbar */
      /* overflow-x: hidden; */
    }

    .nft-section {
        /* margin-top: 1%; */
      height: auto;
      width: 100vw;
      /* background-color: antiquewhite; */
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    .card {
      position: relative;
      /* width: 30%;
      height: 50vh; */
      height: 26rem;
      width: 28rem;
      margin: 1rem;
      font-size: 18px;
      font-weight: bold;
      /* border-radius: 15px; */
      cursor: pointer;
      overflow: hidden;
      margin-top: 1%;
      margin-bottom: 5%;
      border-radius: 50px;
        text-align: center;
    }

    .card img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .details {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      font-family: cursive;
      justify-content: center;
      background-color: rgb(51,33,50,0.72);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s, visibility 0.7s;
    }

    .card:hover .details {
      opacity: 1;
      pointer-events: auto;
    }

    .card {
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      border: none;
      /* margin: 2.5%; */
    }

    .card::after {
      /* box-shadow: 10px 10px 3px  rgb(39,59,80); */
      content: "";
      border-radius: 50px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      /* box-shadow: 0px 0px 15px 15px rgb(39,59,80); */
      opacity: 0;
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) !important;

    }

    .card:hover {
      -webkit-transform: scale(1.10, 1.10) !important;
      transform: scale(1.10, 1.10) !important;
    }

    .card::after {
      opacity: 1 !important;

    }

    .header {
      width: 100%;
      height: 20vh;
      color: white;
      font-family: cursive;
      text-align: center;
    }

    .header h1 {
      margin-top: 2%;
      font-size: 4rem;
    }


    /* body {
  margin: 0;
  padding: 0;
  
} */
    nav {
      /* background-color: ; */
      color: #fff;
      font-family: Arial, sans-serif;
      height: auto;
    }

    .nav-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      margin-top: 0.3rem;
    }

    .nav-logo h1 {
      margin: 0;
      color: #00fefe;
      font-size: 2.5rem;
      padding-left: 2.7rem;
    }

    .nav-links {
      list-style: none;
      display: flex;
      font-size: 1.2rem;
      margin-top: 1.2rem;
      padding-right: 5rem;
    }

    .nav-links li {
      margin-right: 2.5rem;
    }

    .nav-links li a {
      color: #fff;
      text-decoration: none;
      padding: 0.5rem;
    }

    .nav-links li a.active {
      /* background-color: #666; */
      border-bottom: white solid 2px;
    }

    .nav-btn {
      display: none;
    }

    .burger-menu {
      display: none;
      cursor: pointer;
      flex-direction: column;
      justify-content: space-between;
      height: 20px;
    }

    .bar {
      width: 30px;
      height: 3px;
      background-color: #fff;
    }

    .space {
      height: 500px;
      width: 100%;
    }

    #footer-devchef {
      width: 100%;
      height: 17rem;
      background-image: linear-gradient(to right, rgb(21, 27, 61) 30%, rgb(78, 126, 151));
      /* background-color: rgb(14,17,26); */
      display: flex;
      flex-direction: row;
      padding-top: 4%;
      border-top-left-radius: 200px;
      justify-content: space-around;
    }

    @media screen and (max-width: 992px) {
      .copyright {
        position: relative;
        top: -140px;
        left: 120px;
      }

      #footer-devchef {
        height: 500px;
        border-top-left-radius: 100px;
        margin-top: 50%;
        flex-direction: column;
        justify-content: space-around;
      }

      #devchef-header {
        left: 39px;
        position: relative;
        top: -30px;
        font-size: 0.5rem !important;
      }

      #social-icons {
        position: relative;
        top: -100px;
        left: 40px;
      }

      .nav-links {
        display: none;
      }

      .nav-btn {
        display: block;
      }


      .nav-links.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 70px;
        left: 0;
        background-color: rgb(4, 7, 27);
        width: 100%;
      }

      .nav-links li {
        margin: 1rem;
      }

      .nav-links li a {
        padding: 1rem;
        text-align: center;
      }

      .nav-links li a.active {
        background-color: transparent;
      }

      .nav-links {
        display: none;
      }

      .burger-menu {
        display: flex;
      }

      .burger-menu.active {

        background-color: rgb(4, 7, 27);
      }

      .nav-links.active {
        display: flex;
        flex-direction: column;

      }

      .nav-links {
        display: none;
        transition: transform 0.3s ease;
        /* Added transition */
        transform: translateY(-100%);
        background-color: #333;
        color: #00fefe;
        font-family: sans-serif;
        position: absolute;
        top: 80px;
        /* Adjust as needed */
        left: 0;
        width: 100%;
        padding: 10px;
        border-radius: 5px;
      }

      .nav-links.active {
        display: flex;
        font-size: 1rem;
        position: absolute;
        top: 50px;
        z-index: 1;
        flex-direction: column;
        transform: translateY(0);
      }

      .burger-menu.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(-45deg);
      }

      .burger-menu.active .bar:nth-child(2) {
        opacity: 0;
      }

      .burger-menu.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(45deg);
      }

      .card {
        height: 20rem;
        width: 22rem;
        margin: 2%;
        margin-bottom: 5%;
      }

      .nav-logo h1 {
        font-weight: bolder;
      }

      .nft-section {
        height: 100vh;
      }

      .nav-logo h1 {
        padding-left: 0px;
      }

      .header {
        height: 15vh !important;
        margin-top: 10%;
        margin-bottom: 6%;
      }
    }


    .loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(4, 7, 27);
      z-index: 9999;
      opacity: 1;
      transition: opacity 0.5s ease;
      /* Add the transition for smooth opacity change */
    }

    .spinner {
      position: absolute;
      width: 10px;
      height: 10px;
    }

    .spinner div {
      position: absolute;
      width: 50%;
      height: 150%;
      background: rgb(0, 249, 249);
      transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
      animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
    }

    .spinner div:nth-child(1) {
      --delay: 0.1;
      --rotation: 36;
      --translation: 150;
    }

    .spinner div:nth-child(2) {
      --delay: 0.2;
      --rotation: 72;
      --translation: 150;
    }

    .spinner div:nth-child(3) {
      --delay: 0.3;
      --rotation: 108;
      --translation: 150;
    }

    .spinner div:nth-child(4) {
      --delay: 0.4;
      --rotation: 144;
      --translation: 150;
    }

    .spinner div:nth-child(5) {
      --delay: 0.5;
      --rotation: 180;
      --translation: 150;
    }

    .spinner div:nth-child(6) {
      --delay: 0.6;
      --rotation: 216;
      --translation: 150;
    }

    .spinner div:nth-child(7) {
      --delay: 0.7;
      --rotation: 252;
      --translation: 150;
    }

    .spinner div:nth-child(8) {
      --delay: 0.8;
      --rotation: 288;
      --translation: 150;
    }

    .spinner div:nth-child(9) {
      --delay: 0.9;
      --rotation: 324;
      --translation: 150;
    }

    .spinner div:nth-child(10) {
      --delay: 1;
      --rotation: 360;
      --translation: 150;
    }

    @keyframes spinner-fzua35 {

      0%,
      10%,
      20%,
      30%,
      50%,
      60%,
      70%,
      80%,
      90%,
      100% {
        transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
      }

      50% {
        transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
      }
    }
    #devchef-header{
      width: max-content;
    }
    #devchef-header h1{
      width: max-content;
    }
    #social-icons{
      width: max-content;
    }
    .copyright{
      width: max-content;
    }
  
